.map {
  width: 100%;
  min-height: 400px;
  margin: 0 auto;

  background-color: $color-beige;
  background-image: url("../img/content/map.png");
  background-size: cover;
  background-position: 40% center;
  background-repeat: no-repeat;

  object-fit: cover;

  @include retina {
    background-image: url("../img/content/map@2x.png");
  }
}
