.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  padding-top: 32px;
  padding-bottom: 18px;

  list-style: none;

  &--catalog {
    padding-top: 12px;
    padding-bottom: 15px;
  }

  &__item {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }

    &:not(:last-child)::after {
      content: "/";
    }
  }

  &__link {
    font-weight: 300;
    font-size: 14px;
    line-height: 1.4;

    &[href] {
      color: $color-default-dark-grey;
      transition: $trans--300;
    }

    &--active {
      color: $color-grey;

      pointer-events: none;
    }
  }

  &__link[href]:hover {
    color: $color-green;
  }
}
