.side-nav {
  width: 250px;
  margin: 0;
  padding: 0;

  border-bottom: 1px solid $color-light-grey;
  background-color: $color-default-white;
  list-style: none;

  &__item {
    position: relative;

    border-top: 1px solid $color-light-grey;
    border-right: 1px solid $color-light-grey;
    border-left: 1px solid $color-light-grey;

    &::after {
      content: "";

      position: absolute;
      top: 19px;
      right: 20px;

      width: 6px;
      height: 6px;

      border-top: 2px solid $color-eclipse;
      border-right: 2px solid $color-eclipse;
      transform: rotate(45deg);
    }
  }

  &__link {
    display: block;
    padding: 17px 18px 15px;
  }

  &__subnav {
    padding: 18px 18px 23px;

    border-top: 1px solid $color-light-grey;
    list-style: none;
    opacity: 1;
  }

  &__subnav-item {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__subnav-link {
    display: block;
  }
}

.there-is--js .side-nav {
  &__subnav {
    display: none;
  }
}
