.services {
  padding-top: 35px;
  padding-bottom: 30px;

  &__list {
    display: flex;
    margin: 0;
    margin-right: 16px;
    margin-left: 16px;
    padding: 0;

    list-style: none;
  }

  &__item {
    display: flex;
    align-items: center;
    min-height: 120px;
    margin-right: 53px;
  }

  &__item:last-child {
    margin-right: 0;
  }

  &__button {
    display: block;
    background-color: transparent;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    padding: 0;

    background-repeat: no-repeat;
    background-position: center;
    border: none;
    cursor: default;


    @media (min-width: $vp--1024) {
      cursor: pointer;

      transition: $trans--300;

      &:hover {
        opacity: $opacity--main;
      }
    }
  }

  &__button--active {
    @media (min-width: $vp--1024) {
      cursor: default;
    }
  }

  &__button--active:hover {
    @media (min-width: $vp--1024) {
      opacity: 1;
    }
  }


  &__item--сonsultation .services__button {
    background-image: url("../img/style/service-1.png");

    @media (min-width: $vp--1024) {
      &--active {
        background-image: url("../img/style/service-green-1.png");
      }
    }
  }

  &__item--design .services__button {
    background-image: url("../img/style/service-2.png");

    @media (min-width: $vp--1024) {
      &--active {
        background-image: url("../img/style/service-green-2.png");
      }
    }
  }

  &__item--мanufacturing .services__button {
    background-image: url("../img/style/service-3.png");

    @media (min-width: $vp--1024) {
      &--active {
        background-image: url("../img/style/service-green-3.png");
      }
    }
  }

  &__item--delivery .services__button {
    background-image: url("../img/style/service-4.png");

    @media (min-width: $vp--1024) {
      &--active {
        background-image: url("../img/style/service-green-4.png");
      }
    }
  }

  &__item--installation .services__button {
    background-image: url("../img/style/service-5.png");

    @media (min-width: $vp--1024) {
      &--active {
        background-image: url("../img/style/service-green-5.png");
      }
    }
  }

  &__item--cleaning .services__button {
    background-image: url("../img/style/service-6.png");

    @media (min-width: $vp--1024) {
      &--active {
        background-image: url("../img/style/service-green-6.png");
      }
    }
  }

  &__item--guarantee .services__button {
    background-image: url("../img/style/service-7.png");

    @media (min-width: $vp--1024) {
      &--active {
        background-image: url("../img/style/service-green-7.png");
      }
    }
  }


  &__explanation {
    width: 485px;
    margin-left: 35px;
  }

  &__explanation--hide {
    display: none;
  }

  &__explanation--show {
    display: flex;
    align-items: center;
  }

  &__title {
    margin: 0;
    margin-right: 45px;

    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    font-family: $font-family--montserrat;
  }

  &__description {
    margin: 0;
    padding-top: 9px;

    font-weight: 300;
    line-height: 22px;
  }
}

@media (max-width: 1300px) {
  .services {
    &__item {
      margin-right: auto;
    }

    &__title {
      margin-right: 35px;
    }

    &__description {
      font-size: 14px;
    }

    &__explanation {
      margin-left: 20px;
      width: 400px;
    }
  }
}

@include vp--1023 {
  .services {
    padding-top: 38px;
    padding-bottom: 52px;

    &__list {
      flex-direction: column;
      max-width: 600px;
      margin: 0 auto;
    }

    &__item {
      align-items: flex-start;
      margin-right: 0;
      margin-bottom: 20px;
      padding-right: 7px;
    }

    &__item:last-child {
      margin-bottom: 0;
    }

    &__explanation {
      display: block;
      width: 100%;
      height: auto;
      margin-left: 8px;
      padding-top: 11px;
    }

    &__item--design .services__explanation {
      display: block;
    }

    &__button {
      margin-right: 0;
    }

    &__title {
      margin: 0;
      margin-right: 0;
      margin-bottom: 8px;
    }

    &__description {
      margin: 0;
      padding-top: 0;

      font-size: 14px;
      line-height: 22px;
    }
  }
}


@include vp--767 {
  .services {
    padding-bottom: 48px;

    &__item {
      margin-bottom: 14px;
    }
  }
}
