@mixin retina {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

// Desktop first

@mixin vp--1023 {
  @media (max-width: $vp--1024 - 1) {
    @content;
  }
}

@mixin vp--767 {
  @media (max-width: $vp--768 - 1) {
    @content;
  }
}

@mixin desktop-width-only {
  @media (min-width: $vp--1024) {
    @content;
  }
}
