.contact-list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  margin-bottom: -40px;
  padding: 57px 50px 60px 50px;

  list-style: none;

  @include vp--1023 {
    justify-content: center;
  }

  @include vp--767 {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 0 40px 0 30px;

    font-family: $font-family--montserrat;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.02em;

    @include vp--1023 {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    margin-bottom: 23px;

    background-color: $color-green;
    border-radius: 50%;
  }

  &__title {
    max-width: 225px;
    margin: 0;
    margin-bottom: 6px;

    text-align: center;
  }

  &__data {
    max-width: 225px;
    margin: 0;

    text-align: center;
  }
}
