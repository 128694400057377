.manufacture {
  padding-bottom: 78px;

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
  }

  &__heading {
    min-width: 252px;
    margin-right: 50px;
    margin-bottom: 15px;
  }

  &__wrapper {
    display: flex;
  }

  &__slider {
    position: relative;

    width: 578px;
    overflow: hidden;
  }

  &__block {
    max-width: 578px;
    margin-right: 50px;
  }

  &__list {
    position: relative;
    top: 0;
    left: 0;

    display: flex;
    height: 100%;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item {
    flex-shrink: 0;
  }

  &__button {
    position: absolute;
    top: 228px;
    z-index: 1;

    width: 40px;
    height: 40px;

    background-color: $color-light-grey;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    transition: $trans--300;

    &::after {
      content: none;
    }
  }

  &__button:active,
  &__button:focus {
    outline: none;
  }

  &__button:hover {
    background-color: $color-green;
  }

  .manufacture__button:hover .manufacture__arrow path {
    stroke: $color-default-white;
  }

  .manufacture__button--left {
    left: 20px;
  }

  .manufacture__button--right {
    right: 20px;
  }

  &__explanation {
    width: 550px;
    padding-top: 4px;

    font-weight: 300;
    line-height: 26px;
  }

  &__first,
  &__second {
    margin-bottom: 25px;
  }

  &__image {
    width: 100%;

    object-fit: cover;
  }
}

@include vp--1023 {
  .manufacture {
    &__wrapper {
      flex-direction: column;
    }

    &__slider {
      position: relative;

      width: 100%;
      margin-right: auto;
      margin-left: auto;
    }

    &__block {
      margin: 0 auto;
      margin-bottom: 30px;
    }

    &__explanation {
      width: auto;
    }

    &__button {
      top: 50%;
    }

    .manufacture__button--left {
      left: 10px;
    }

    .manufacture__button--right {
      right: 10px;
    }
  }
}

@include vp--1023 {
  .manufacture {
    &__block {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .manufacture {
    &__heading {
      margin-bottom: 10px;
    }
  }
}
