/* stylelint-disable property-no-unknown */
/* stylelint-disable property-no-vendor-prefix */
.filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: -10px;
  padding: 0;

  list-style: none;

  &__item {
    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  &__button {
    display: block;
    max-width: 300px;
    padding: 10px 21px;
    padding-left: 20px;

    font-weight: 300;
    color: inherit;

    background-color: $color-light-grey;
    border-radius: 100px;
    border: none;

    cursor: pointer;
    outline: none;
    transition: $trans--300;
    user-select: none;

    &:hover {
      opacity: $opacity--main;
    }

    &--active {
      background-color: $color-green;
      color: $color-default-white;
      opacity: 1;
    }

    &--active:hover {
      opacity: 1;
    }
  }

  &__text {
    display: box;
    box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}
