.categories {
  padding-top: 70px;
  padding-bottom: 88px;
}

.categories__list {
  display: grid;
  height: 581px;
  margin: 0;
  padding: 0;

  list-style: none;

  grid-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.categories__item {
  position: relative;
  max-width: 280px;
  user-select: none;
}

.categories__item:first-child {
  grid-row-end: 3;
  grid-row-start: 1;
  max-height: 581px;
}

.categories__item:nth-child(6) {
  grid-column-end: 5;
  grid-column-start: 3;
  max-width: 580px;
}

.categories__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.categories__title {
  position: absolute;
  bottom: 19px;
  left: 20px;

  min-width: 111px;
  max-width: 170px;
  min-height: 38px;
  margin: 0;
  padding: 9px 15px;
  padding-top: 9px;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  font-family: $font-family--montserrat;
  text-transform: uppercase;

  background-color: $color-default-white;
  transition: $trans--300;
}

.categories__item:nth-child(2) .categories__title {
  bottom: 25px;
}

.categories__item:nth-child(3) .categories__title,
.categories__item:nth-child(4) .categories__title {
  bottom: 25px;

  min-width: 127px;
}

.categories__link:hover .categories__title {
  opacity: $opacity--main;
}

@include vp--1023 {
  .categories {
    margin: 0 auto;
    padding-top: 71px;
    padding-bottom: 35px;
  }

  .categories__list {
    height: auto;
    grid-row-gap: 29px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .categories__item {
    max-width: unset;
  }

  .categories__item:first-child {
    grid-row-end: auto;
    grid-row-start: auto;
  }

  .categories__item:last-child {
    grid-column-end: auto;
    grid-column-start: auto;
  }

  .categories__image {
    margin-bottom: 5px;
    max-height: 250px;
  }

  .categories__title {
    position: relative;
    left: 0;
    top: 0;

    height: auto;
    max-width: unset;
    width: 100%;
    margin: 0;
    padding: 0;

    font-size: 12px;
    line-height: 27px;
    text-align: center;

    background-color: transparent;
  }
}

@include vp--767 {
  .categories {
    padding-top: 72px;
    padding-bottom: 45px;
  }

  .categories__list {
    grid-column-gap: 20px;
    grid-row-gap: 13px;
    grid-template-columns: 1fr 1fr;
  }

  .categories__image {
    max-height: 160px;
  }

  .categories__item {
    max-height: unset;
    min-height: unset;
  }
}
