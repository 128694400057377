.container {
  width: 100%;
  max-width: 1260px;
  margin: 0 auto;
  padding: 0 40px;

  @include vp--1023 {
    padding: 0 20px;
  }

  @include vp--767 {
    padding: 0 10px;
  }
}
