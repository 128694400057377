.about {
  &__breadcrumbs {
    margin-bottom: 18px;
  }

  &__bottom {
    background-color: $color-beige;
    padding-top: 99px;
    padding-bottom: 58px;
  }

  &__wrapper {
    background-color: $color-default-white;
    display: flex;
  }
}

@include vp--1023 {
  .about {
    &__wrapper {
      flex-direction: column;
    }

    &__bottom {
      padding-top: 75px;
      padding-bottom: 0;
    }
  }
}

@include vp--767 {
  .about {
    &__bottom {
      padding-top: 50px;
    }
  }
}
