.modal-slider {
  &__images-wrapper {
    @include vp--1023 {
      position: relative;
    }
  }

  &__main {
    width: 570px;
    height: 428px;

    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    @include vp--767 {
      // width: 100%;
      height: auto;
      max-height: 428px;
    }
  }

  &__main img {
    // max-height: 428px;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    object-fit: cover;


    @include vp--767 {
      width: 100%;
      max-height: 428px;
      align-self: center;
      object-fit: contain;
    }
  }

  &__nav {
    width: 570px;
    height: 94px;
    margin-top: 17px;

    @include vp--767 {
      display: none;
    }
  }

  &__main-images {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__text-wrapper {
    width: 30%;
    margin-left: 25px;

    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;

    @include vp--1023 {
      width: 100%;
      margin-top: 65px;
      margin-left: 0;
    }
  }

  tbody {
    display: flex;
    flex-direction: column;
  }

  tr {
    display: flex;
    flex-direction: column;
  }

  th {
    padding: 0;
    padding-bottom: 10px;
    padding-top: 10px;

    font-weight: bold;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
  }

  td {
    padding: 0;

    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
  }

  &__button {
    margin-top: 19px;
  }

  &__name {
    margin: 0;
  }

  &__footnote {
    width: 60%;
    margin: 0;
    margin-top: 10px;

    font-weight: 400;
    font-size: 12px;
    line-height: 1.33;
    color: $color-grey;

    @include vp--1023 {
      width: 100%;
      margin: 0 auto;
      margin-top: 10px;
    }
  }

  &__thumbnails {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    margin-right: -10px;

    list-style: none;
  }

  &__thumbnail {
    border: 4px solid transparent;

    &:hover {
      opacity: 0.7;
    }

    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0;

      object-fit: cover;
    }

    &.swiper-slide-thumb-active {
      border-color: rgba(0, 0, 0, 0.15);
      opacity: 0.6;
    }
  }

  &__button--prev {
    position: absolute;
    z-index: 10;
    bottom: 22px;
    right: 72px;

    margin: 0;

    @include vp--1023 {
      bottom: -55px;
      left: 10px;
    }

    @include vp--767 {
      left: 10px;
    }
  }

  &__button--next {
    position: absolute;
    z-index: 10;
    bottom: 22px;
    right: 21px;

    margin: 0;

    @include vp--1023 {
      bottom: -55px;
      right: 10px;
    }

    @include vp--767 {
      position: absolute;
      transform: rotate(180deg);
      left: auto;
      right: 10px;
    }
  }
}

// Модификатор для вертикальных фото в слайдере
.modal-slider__images-wrapper--vertical {
  display: flex;

  .modal-slider__main {
    width: 428px;
    height: 570px;
    margin-right: 20px;

    @include vp--767 {
      height: auto;
    }
  }

  .modal-slider__nav {
    width: 90px;
    height: 570px;
    margin-top: 0;
  }

  .modal-slider__thumbnail {
    width: 90px;
    height: 180px;
  }

  img {
    height: 100%;

    object-fit: cover;
  }

  @include vp--1023 {
    .modal-slider__button--prev {
      left: 20px;
    }

    .modal-slider__button--next {
      right: 143px;
    }
  }

  @include vp--767 {
    .modal-slider__main {
      margin: 0 auto;
    }

    .modal-slider__button--prev {
      left: 13%;
    }

    .modal-slider__button--next {
      right: 13%;
    }
  }
}
