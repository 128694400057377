.page-title {
  margin: 0;

  font-family: $font-family--montserrat;
  font-weight: 500;
  font-size: 32px;
  line-height: 45px;

  @include vp--1023 {
    font-size: 22px;
  }
}
