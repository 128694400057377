.phone-info {
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 220px;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 15px;

  text-align: right;

  @include desktop-width-only {
    border-right: 1px solid $color-light-grey-2;
  }

  @media (max-width: 1250px) {
    padding-right: 12px;
  }

  @include vp--1023 {
    max-width: 200px;
    margin-bottom: 0;
    padding-right: 0;

    text-align: left;
  }

  &__schedule {

    font-size: 12px;
    line-height: 1.33;

    @include vp--1023 {
      padding-right: 0;
    }

    @include vp--767 {
      margin-left: 0;
      max-width: 80%;

      font-size: 10px;
      line-height: 1.6;
    }
  }

  &__number {
    display: block;
    margin-top: 4px;

    font-weight: bold;
    font-size: 24px;
    line-height: 1;
    outline: none;
    transition: $trans--300;
    text-decoration: none;

    &:hover,
    &:active,
    &:focus {
      color: $color-green;
    }

    @media (max-width: 1250px) {
      font-size: 22px;
    }

    @media (max-width: 1150px) {
      font-size: 20px;
    }

    @include vp--767 {
      margin-top: 0;
      font-size: 18px;
      line-height: 1.33;
    }
  }
}
