.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  background-color: rgba($color-default-black, 0.6);
  transform: scale(1.2);
  visibility: hidden;
  opacity: 0;

  transition: opacity $trans--600,
    transform $trans--600,
    visibility $trans--600;

  pointer-events: none;

  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;

    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
    padding: 60px 40px;

    @include vp--767 {
      padding: 60px 16px;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    cursor: pointer;
  }

  &__content {
    position: relative;

    width: 600px;
    padding: 40px;
    padding-top: 30px;

    background-color: $color-default-white;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);
    border-radius: 5px;

    @include vp--767 {
      width: 100%;
      padding: 20px;
    }
  }

  &__close-btn {
    position: absolute;
    top: 13px;
    right: 13px;

    padding: 0;

    background: $color-transparent;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: $opacity--main;
    }
  }

  &--responsive {
    .modal__content {
      width: 100%;
    }
  }

  &--fit-content .modal__content {
    width: auto;

    @include vp--767 {
      width: 100%;
    }
  }

  &--no-scale {
    transform: none;
  }

  &--preload {
    display: none;
  }

  &--active {
    transform: scale(1);
    visibility: visible;
    opacity: 1;

    pointer-events: auto;
  }

  &--callback {
    .modal__content {
      max-width: 380px;
    }
  }

  &--slider .modal__content {
    display: flex;
    max-width: 962px;
    padding: 30px 20px 47px;
    padding-left: 30px;
    z-index: 10;

    @include desktop-width-only {
      align-items: flex-start;
    }

    @include vp--1023 {
      flex-direction: column;
      max-width: 630px;
      padding-right: 30px;
      padding-bottom: 40px;
    }

    @include vp--767 {
      width: 100%;
      height: 100%;

      border-radius: 0;
    }
  }

  &--slider .modal__wrapper {
    @include vp--767 {
      padding: 0;
    }
  }
}
