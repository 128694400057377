.catalog {
  &__inner {
    display: flex;
    margin-top: 40px;
  }

  &__side-nav {
    margin-bottom: 40px;
  }

  &__heading {
    margin: 0;
    margin-bottom: 15px;

    font-family: $font-family--montserrat;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.4;
  }

  &__intro {
    max-width: 852px;
    margin: 0;
    margin-bottom: 32px;
  }

  &__intro p {
    margin: 0;
    margin-bottom: 15px;

    font-weight: 300;
  }

  &__side-wrapper {
    margin-right: 48px;

    @include vp--767 {
      display: none;
    }
  }

  &__features {
    margin-bottom: 20px;
  }

  &__filter {
    margin-bottom: 30px;
  }

  &__works h2 {
    margin: 0;
    margin-bottom: 29px;

    font-family: $font-family--montserrat;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.4;
  }

  &__works-list {
    margin-bottom: 30px;
  }

  &__conclusion {
    margin-bottom: 79px;

    @include vp--767 {
      margin-bottom: 45px;
    }

    p {
      max-width: 840px;
      margin: 0;
      margin-bottom: 32px;

      font-weight: 300;
    }

    h3 {
      margin: 0;
      margin-bottom: 10px;

      font-family: $font-family--montserrat;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.6;
      letter-spacing: 0.02em;
    }
  }

  &__list {
    margin: 0;
    margin-bottom: 14px;
    padding: 0;

    font-weight: 300;

    list-style: none;
  }

  &__list-item {
    position: relative;

    padding-left: 22px;

    &::before {
      content: "";
      position: absolute;
      top: 10px;
      left: 0;

      width: 10px;
      height: 10px;

      background-image: url("../img/svg/tick.png");
    }
  }
}
