.social {
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: -10px;
  margin-left: -10px;
  padding: 0;

  list-style: none;

  @include vp--767 {
    align-items: flex-start;
  }

  &__item {
    margin-bottom: 5px;
    margin-left: 10px;
  }

  &__link {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;

    border-radius: 50%;
    transition: $trans--300;
  }

  &__link::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 11px;

    width: 14px;
    height: 14px;

    background-repeat: no-repeat;
    background-position: center;
  }

  .social__image--telegram::before {
    background-image: url("../img/style/telegram.svg");
    top: 9.5px;
    left: 9px;
  }

  .social__image--whatsapp::before {
    background-image: url("../img/style/whatsapp.svg");
  }

  .social__image--instagram::before {
    background-image: url("../img/style/instagram.svg");
  }

  &__image {
    position: absolute;

    width: 14px;
    height: 14px;

    color: $color-default-white;
  }

  .social__link--green {
    background-color: $color-green;
  }

  .social__link--brown {
    background-color: $color-brown;
    outline: none;
    transition: $trans--300;

    @include vp--767 {
      width: 30px;
      height: 30px;
    }

    &:hover,
    &:active,
    &:focus {
      background-color: $color-green;
    }
  }

  .social__link--brown .social__image {
    @include vp--767 {
      width: 14px;
      height: 14px;
    }
  }

  .social__link--brown.social__image--instagram::before {
    top: 10px;
  }

  @include vp--767 {
    .social__link--brown::before {
      background-size: 13px 13px;
      top: 7px;
      left: 8px;
    }

    .social__link--brown.social__image--telegram::before {
      left: 7px;
    }

    .social__link--brown.social__image--instagram::before {
      top: 7px;
    }
  }
}
