.showrooms {
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0;
    margin-bottom: 30px;
    padding: 0;

    list-style: none;
  }

  &__card {
    display: flex;
    flex-direction: column;
    width: 48.3%;
    max-width: 570px;
    margin-right: 3.3%;
    margin-bottom: 40px;

    background-color: $color-beige;

    &:nth-child(2n) {
      margin-right: 0;
    }

    @include vp--1023 {
      width: 100%;
      max-width: 100%;
      margin-right: 0;

      margin-bottom: 40px;
    }
  }

  &__card:last-child {
    @include vp--1023 {
      margin-bottom: 0;
    }
  }

  &__heading {
    margin-bottom: 43px;
  }

  &__card-heading {
    margin: 0;
    margin-bottom: 5px;

    font-family: $font-family--montserrat;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;

    @include vp--1023 {
      font-size: 16px;
    }
  }

  &__card-img {
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 320px;

    img {
      position: absolute;
      z-index: -100;

      display: block;
      height: 320px;
      width: 100%;

      object-fit: cover;
      background-color: $color-beige;
    }
  }

  &__card-desc {
    margin-top: auto;
    padding: 18px 22px;

    color: $color-default-white;
  }

  &__location {
    margin: 0;

    font-weight: 300;
  }

  &__card-content {
    tbody {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 20px 17px 12px 18px;
    }

    a {
      display: block;
    }
  }

  &__card-content tr {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-bottom: 6px;

    @include vp--767 {
      flex-direction: column;
    }
  }

  &__card-content tr:last-child {
    flex-direction: column;

    th,
    td {
      width: 100%;
    }
  }


  &__card-content th {
    width: 34%;
    padding-right: 10px;

    vertical-align: top;
    font-family: $font-family--montserrat;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.02em;

    @include vp--767 {
      width: 100%;
    }
  }

  &__card-content td {
    flex-direction: column;
    flex-wrap: wrap;
    width: 66%;
    margin: 0;
    padding-top: 5px;
    padding-bottom: 5px;

    font-weight: 300;

    a[href] {
      transition: $trans--300;
      text-decoration: none;
    }

    a[href]:hover {
      color: $color-green;
    }

    @include vp--767 {
      width: 100%;
    }
  }

  &__phones-link {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
