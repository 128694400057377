.dropdown {
  &__panel {
    opacity: 0;
    // top: -500px;
    transition: opacity $trans--300;

    @include vp--1023 {
      display: block;
      opacity: 1;
    }
  }

  &__panel--one-columns {
    @include desktop-width-only {
      min-width: calc(100% + 50px);
      max-width: calc(140% + 50px);
    }
  }

  &__panel--two-columns {
    @include desktop-width-only {
      min-width: calc(100% * 2 + 50px + 80px);
      max-width: calc(140% * 2 + 50px + 80px);
      column-count: 2;
      column-gap: 80px;
    }
  }

  &--active .dropdown__panel {
    &--side {
      display: block;
    }

    @include vp--1023 {
      display: block;
    }
  }

  &--active.side-nav__item--dropdown::after {
    border-top: 2px solid $color-green;
    border-right: 2px solid $color-green;

    transform: rotate(135deg);
  }

  &--active.dropdown::after {
    @include vp--1023 {
      border-top: 2px solid $color-green;
      border-right: 2px solid $color-green;

      transform: rotate(135deg);
    }
  }
}

.is-menu-open .dropdown {
  &__panel {
    opacity: 1;
    top: unset;
  }
}


// При наличии js
.there-is--js .dropdown {
  &__panel {
    @include vp--1023 {
      display: none;
    }
  }

  &--active .dropdown__panel {
    display: block;
  }
}
