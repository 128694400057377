.about-form {
  width: 731px;
  padding-top: 44px;
  padding-right: 76px;
  padding-bottom: 50px;
  padding-left: 75px;

  &__heading {
    margin-bottom: 30px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    width: 280px;
    margin-bottom: 24px;

    @media (max-width: 1275px) {
      width: 100%;
    }
  }

  &__item:nth-child(odd) {
    margin-right: 20px;

    @media (max-width: 1275px) {
      margin-right: 0;
    }
  }

  .about-form__item--textaria {
    width: 100%;
    margin-right: 0;
    margin-bottom: 31px;
  }

  &__label {
    display: block;
    margin-bottom: 8px;

    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &__input {
    width: 100%;
    height: 45px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;

    border: 1px solid $color-medium-grey;
    border-radius: 4px;
    cursor: pointer;
    transition: $trans--300;
  }

  &__input:hover {
    border-color: $color-grey;
  }

  &__input:focus {
    color: $color-default-dark-grey;

    border-color: $color-grey;
    outline: none;
  }

  .about-form__input--textaria {
    width: 100%;
    height: 110px;
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  &__button {
    margin-right: 20px;
    padding: 14px 25px 12px 25px;

    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $color-default-white;
    text-transform: uppercase;
    letter-spacing: 0.03em;

    background-color: $color-green;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color $trans--300;
    user-select: none;
  }

  &__button:hover {
    padding: 12px 23px 10px 23px;

    color: $color-green;

    border: 2px solid $color-green;
    background-color: $color-default-white;
  }

  &__description-wrapper {
    width: 295px;
    margin: 0;
    padding: 0;
  }

  &__description {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: $color-grey;
  }

  &__description--link {
    color: $color-brown;
    text-decoration: underline;
    transition: $trans--300;
  }

  &__description--link:hover {
    opacity: 0.5;
  }

  &__error {
    display: none;
  }
}

@include vp--1023 {
  .about-form {
    width: auto;
    padding-right: 0;
    padding-left: 0;

    &__heading {
      padding-left: 75px;
    }

    &__wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      width: 70%;
    }

    .about-form__item--textaria {
      width: 70%;
      margin-bottom: 31px;
    }

    .about-form__input--textaria {
      width: 100%;
    }

    &__bottom {
      flex-direction: column;
      align-items: flex-start;
      width: 70%;
    }

    &__button {
      margin-bottom: 10px;
    }

    &__description-wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .about-form {
    padding: 5%;

    &__heading {
      padding-left: 0;
    }
  }
}

//стили валидации формы
.about-form__item--error {
  .about-form__input {
    border-color: $color-red;
    margin-bottom: 8px;
  }

  .about-form__error {
    position: relative;
    display: block;
    padding: 5px 15px 5px 40px;
    color: $color-red;
    background-color: rgba($color-red, 0.2);
    border-radius: 5px;

    &::before {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      top: 50%;
      left: 17px;
      transform: translateY(-58%);
      background-image: url("../img/style/warning.png");
    }
  }
}
