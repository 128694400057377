@font-face {
  font-style: normal;
  font-weight: 700;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/roboto-bold.woff2") format("woff2"),
    url("../fonts/roboto-bold.woff2") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 300;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/roboto-light.woff2") format("woff2"),
    url("../fonts/roboto-light.woff2") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/roboto-medium.woff2") format("woff2"),
    url("../fonts/roboto-medium.woff2") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Roboto";

  font-display: swap;
  src:
    url("../fonts/roboto-regular.woff2") format("woff2"),
    url("../fonts/roboto-regular.woff2") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 500;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/montserrat-medium.woff2") format("woff2"),
    url("../fonts/montserrat-medium.woff2") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Montserrat";

  font-display: swap;
  src:
    url("../fonts/montserrat-semibold.woff2") format("woff2"),
    url("../fonts/montserrat-semibold.woff2") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "Source Sans Pro";

  font-display: swap;
  src:
    url("../fonts/sourcesanspro-regular.woff2") format("woff2"),
    url("../fonts/sourcesanspro-regular.woff2") format("woff");
}

@font-face {
  font-style: normal;
  font-weight: 600;
  font-family: "Source Sans Pro";

  font-display: swap;
  src:
    url("../fonts/sourcesanspro-semibold.woff2") format("woff2"),
    url("../fonts/sourcesanspro-semibold.woff2") format("woff");
}
