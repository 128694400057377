.header {
  position: relative;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 0.8fr 1.2fr 0.9fr;


  @include vp--1023 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &.container {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__logo-wrapper {
    display: flex;

    @include vp--1023 {
      justify-content: space-between;
      width: 100%;
      min-height: 78px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__logo {
    display: block;
    transition: $trans--300;

    @include vp--1023 {
      align-self: flex-start;
    }

    &[href]:hover > svg {
      opacity: 0.7;
    }

    @include vp--1023 {
      height: auto;
    }
  }

  &__logo--desktop {
    margin-top: 18px;
    margin-bottom: 22px;
    width: 100%;

    @include vp--1023 {
      display: none;
    }
  }

  &__logo--mobile {
    display: none;

    @include vp--1023 {
      display: block;
      margin-top: 22px;
      margin-bottom: 19px;
    }
  }

  &__contacts-wrapper {
    position: relative;

    display: flex;
    justify-content: flex-end;
    padding-top: 33px;
    padding-bottom: 33px;

    @include vp--1023 {
      order: 2;
      justify-content: space-between;
      width: 100%;
      padding: 0;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 8px;
    }
  }

  &__buttons-wrapper {
    max-width: 640px;
    margin-top: -5px;
    justify-content: flex-end;

    @include vp--1023 {
      max-width: unset;
      order: 3;
      margin: 0;
      margin-top: 20px;
      margin-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
      flex-shrink: 0;
    }
  }

  &__phone {
    margin-right: 17px;

    @media (max-width: 1250px) {
      margin-right: 10px;
    }
  }

  &__toggle-menu {
    position: absolute;
    top: 26px;
    right: 0;

    display: none;
    width: 30px;
    height: 21px;

    border: none;
    background-color: inherit;

    cursor: pointer;
    outline: none;

    @include vp--1023 {
      position: relative;
    }
  }

  &__icon-menu {
    @include vp--1023 {
      position: absolute;
      left: 0;
      top: 0;
      background-color: $color-green;
      transition: $trans--300;
      width: 30px;
      height: 3px;
    }
  }

  &__icon-menu:nth-child(2n) {
    @include vp--1023 {
      top: 9px;
    }
  }

  &__icon-menu:last-child {
    @include vp--1023 {
      top: unset;
      bottom: 0;
    }
  }

  &__toggle-menu:hover .header__icon-menu {
    @include vp--1023 {
      background-color: $color-brown;
    }
  }

  &__toggle-menu:focus .header__icon-menu {
    @include vp--1023 {
      background-color: $color-brown;
    }
  }

  &__toggle-menu:active .header__icon-menu {
    @include vp--1023 {
      background-color: $color-brown;
    }
  }

  & .main-nav {
    grid-column-start: 1;
    grid-column-end: 4;

    @include vp--1023 {
      display: block;
    }
  }

  &__social {
    @include vp--1023 {
      width: fit-content;
    }
  }
}

// При наличи js
.there-is--js .header {
  &__main-nav {
    @include vp--1023 {
      display: none;
    }
  }

  &__toggle-menu {
    display: block;
  }

  &__contacts-wrapper::before {
    @include vp--1023 {
      content: "";
      position: absolute;

      width: 300vw;
      height: 1px;
      margin-left: calc(50% - 50vw);
      left: 0;
      bottom: -4px;

      background-color: $color-light-grey;
    }
  }

  &__contacts-wrapper::after {
    @include vp--1023 {
      content: "";
      position: absolute;

      width: 150vw;
      height: 1px;
      margin-left: calc(50% - 50vw);
      left: 0;
      top: -2px;

      background-color: $color-light-grey;
    }
  }

  &__buttons-wrapper {
    @include vp--1023 {
      margin-top: 0;
    }
  }
}

// Клас .is-menu-open для работы мобильного меню
.is-menu-open {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  flex-wrap: nowrap;
  width: 100%;
  height: calc(100 * var(--vh, 1vh));

  background-color: $color-default-white;

  overflow-x: hidden;
  overflow-y: scroll;

  .header__icon-menu {
    display: none;
  }

  .header {
    padding-bottom: 50px;

    &__toggle-menu {
      position: absolute;

      top: 24px;
      right: 25px;

      width: 23px;
      height: 23px;
    }

    &__buttons-wrapper {
      max-width: unset;
    }

    &__contacts-wrapper {
      width: fit-content;
      flex-shrink: 0;
    }
  }
}

@include vp--1023 {
  .is-menu-open .header {
    &__toggle-menu {
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: -4px;

        width: 30px;
        height: 3px;

        background-color: $color-green;
      }

      &::before {
        transform: rotate(45deg);
      }

      &::after {
        transform: rotate(-45deg);
      }

      &:hover::before,
      &:active::before,
      &:focus::before,
      &:hover::after,
      &:active::after,
      &:focus::after {
        background-color: $color-brown;
      }
    }

    &__main-nav {
      display: block;
      order: 1;
    }

    &__contacts-wrapper {
      margin-bottom: 39px;
      width: 100%;

      &::before {
        display: none;
      }

      &::after {
        display: none;
      }
    }
  }
}
