.extra-menu {
  display: block;
  columns: 1;
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    width: 141px;
    margin-bottom: 11px;

    text-align: center;
  }

  &__link {
    display: block;
  }
}

@media (min-width: $vp--1024) {
  .extra-menu {
    display: block;
    width: 334px;
    columns: 2;
    column-gap: 57px;

    &__item {
      text-align: left;
    }
  }
}
