.section {
  &__title {
    margin: 0;

    font-weight: 500;
    font-size: 26px;
    line-height: 36px;
    font-family: $font-family--montserrat;

    @include vp--1023 {
      font-size: 24px;
      line-height: 34px;
    }
  }

  &__description {
    max-width: 562px;
    margin: 0 auto;

    font-weight: 300;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0;

    @include vp--1023 {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.section.services,
.section.features {
  background-color: $color-beige;
}
