.user-links {
  margin: 0;
  padding: 0;

  list-style: none;

  &__item {
    margin-bottom: 10px;

    text-align: center;
  }

  &__item:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: $vp--1024) {
  .user-links {
    display: flex;

    &__item {
      margin-right: 49px;
      margin-bottom: 0;

      text-align: left;
    }

    &__item:last-child {
      margin-right: 0;
    }
  }
}
