.button-block {
  display: flex;
  justify-content: center;
  padding-top: 38px;
  padding-bottom: 19px;

  @include vp--1023 {
    // flex-direction: row-reverse;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @include vp--767 {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
  }

  &__button {
    display: flex;
    margin-bottom: 15px;
    padding: 11px 16px 11px 12px;

    align-items: center;
    font-size: 14px;
    line-height: 1.14;
    color: $color-green;

    border: 2px solid $color-green;
    background-color: $color-default-white;
    text-decoration: none;

    cursor: pointer;
    transition: $trans--300;
    user-select: none;

    @media (max-width: 1250px) {
      font-size: 12px;
      line-height: 12px;
    }

    @include vp--1023 {
      margin-bottom: 10px;
      justify-content: center;
    }

    @include vp--767 {
      width: 100%;
      margin: 0;
      margin-bottom: 8px;
      padding-right: 30px;
    }

    &:hover {
      color: $color-brown;

      border-color: $color-brown;
    }
  }

  &__button:not(:last-child) {
    margin-right: 19px;

    @include vp--1023 {
      margin-right: 0;
    }
  }

  &__button:hover > .button-block__icon {
    color: $color-brown;
  }

  &__icon {
    align-items: center;
    margin-right: 12px;

    @include vp--1023 {
      margin-right: 7px;
    }
  }
}

.button-block__button--callback .button-block__icon {
  margin-top: -4px;
}

// При наличи js
.there-is--js {
  .button-block {
    @include vp--1023 {
      display: none;
    }
  }
}

// Клас .is-menu-open для работы мобильного меню
.is-menu-open {
  .button-block {
    @include vp--1023 {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .button-block__button:not(:last-child) {
    margin-right: 0;
  }
}
