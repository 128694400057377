/* stylelint-disable property-no-unknown */
/* stylelint-disable property-no-vendor-prefix */
.works-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  margin: 0;
  margin-bottom: -45px;
  padding: 0;

  list-style: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr;
  }

  @include vp--767 {
    column-gap: 10px;
  }

  &__item {
    position: relative;

    display: flex;
    flex-direction: column;
    flex-grow: 1;

    margin-bottom: 45px;

    &--hover {
      position: absolute;
      z-index: 1;
      top: -15px;
      left: -30px;

      opacity: 0;
      transition: $trans--300;
      padding: 30px;
      width: calc(100% + 60px);

      background-color: $color-default-white;
      margin-top: -15px;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.1);
      pointer-events: none;
    }

    @include vp--1023 {
      height: auto;
    }
  }

  &__item:hover .works-list__img {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      width: 40px;
      height: 40px;
      background-image: url("../img/sprite/icon-search.svg");
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 15;
    }
  }

  &__item:hover .works-list__item--hover {
    @include desktop-width-only {
      z-index: 10;
      opacity: 1;
      cursor: pointer;
      pointer-events: visible;
    }
  }

  &__item:hover .works-list__item--hover .works-list__button {
    @include desktop-width-only {
      margin-top: 30px;
      display: block;
      max-width: 100%;
    }
  }

  &__item--hover img {
    @include desktop-width-only {
      margin-bottom: 10px;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    user-select: none;

    @include vp--767 {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__title,
  &__size {
    max-width: 280px;
    margin: 0;

    line-height: 22px;
  }

  &__title {
    font-size: 16px;
    line-height: 22px;
    font-weight: 300;
    display: box;
    box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;

    @include vp--1023 {
      margin-bottom: 12px;

      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
  }

  &__size {
    margin-bottom: 8px;

    color: $color-suva-grey;

    @include vp--1023 {
      margin-bottom: 4px;

      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
    }
  }

  &__button {
    display: none;
    width: 100%;
    margin-top: auto;
    padding-top: 13px;
    padding-bottom: 12px;

    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: $color-default-white;
    text-transform: uppercase;

    background-color: $color-green;
    border: 2px solid $color-green;
    border-radius: 4px;

    @include vp--1023 {
      display: block;
    }

    @include vp--767 {
      font-size: 10px;
      line-height: 12px;
      padding: 10px 5px;
    }

    @media (max-width: 360px) {
      font-size: 10px;
    }
  }

  &__img {
    position: relative;
    padding-top: 129%;
    margin-bottom: 12px;

    @include vp--767 {
      padding-top: 132%;
    }
  }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.works-list--main {
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include vp--767 {
    grid-template-columns: 1fr 1fr;

    .works-list__item:nth-child(1) {
      order: 1;
    }

    .works-list__item:nth-child(2) {
      order: 2;
    }

    .works-list__item:nth-child(3) {
      order: 3;
    }

    .works-list__item:nth-child(4) {
      order: 4;
    }

    .works-list__item:nth-child(5) {
      order: 6;
    }

    .works-list__item:nth-child(6) {
      order: 8;
    }

    .works-list__item:nth-child(7) {
      order: 5;
    }

    .works-list__item:nth-child(8) {
      order: 7;
    }

    .works-list__title {
      letter-spacing: 0.2px;
    }

    .works-list__item {
      margin-bottom: 42px;
    }
  }

  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
