.link {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  font-family: $font-family--main;
  color: $color-grey;
  text-decoration: none;
  transition: $trans--300;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: $opacity--main;
  }

  @include vp--767 {
    font-size: 14px;
    line-height: 20px;
  }

  &--phone {
    font-size: 21px;
    line-height: 29px;
  }

  &--info {
    color: $color-brown;
    text-decoration: underline;

    @include vp--767 {
      font-size: 14px;
      line-height: 20px;
      color: $color-green;
    }
  }

  &--white {
    color: $color-default-white;
  }

  &--nav {
    font-size: 12px;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: 0.03em;
    font-family: $font-family--montserrat;
    color: $color-default-dark-grey;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      color: $color-green;
    }

    @media (max-width: 1250px) {
      font-size: 11px;
    }
  }

  &--subnav {
    font-size: 14px;
    line-height: 16px;
    color: $color-matterhorn;

    &[href]:hover,
    &[href]:active,
    &[href]:focus {
      color: $color-green;
    }
  }

  &--data {
    font-family: $font-family--montserrat;
    font-size: 18px;
    line-height: 1.6;
    font-weight: 500;
    letter-spacing: 0.02em;

    &[href^="tel:"] {
      color: $color-grey;
    }

    &[href]:hover {
      color: $color-green;
    }

    @include vp--767 {
      font-size: 18px;
      line-height: 29px;
    }
  }

  &--brown {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
  }
}

.header--opened .link--nav {
  font-size: 12px;
}
