.about-button {
  display: block;
  padding: 14px 19px;
  padding-bottom: 11px;

  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $color-green;
  text-transform: uppercase;
  letter-spacing: 0.03em;

  border: 2px solid $color-green;
  border-radius: 4px;
  transition: $trans--300;
  text-decoration: none;
  user-select: none;

  &:hover {
    opacity: $opacity--main;
  }
}
