.reviews {
  padding-bottom: 100px;

  &__top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
  }

  &__heading {
    min-width: 252px;
    margin-right: 50px;
    margin-bottom: 15px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item:nth-child(odd) {
    margin-right: 21px;
  }

  &__text {
    position: relative;

    width: 418px;
    padding: 21px 30px 22px 32px;

    font-weight: 300;
    font-size: 14px;
    line-height: 25px;

    border: 1px solid $color-medium-grey;
    border-radius: 3px;
  }

  &__wrapper {
    margin: 0;
    padding: 0;
  }

  &__description {
    position: absolute;
    bottom: -60px;
    left: 56px;
  }

  &__criterion {
    font-weight: 500;
  }

  &__author,
  &__date {
    margin: 0;
    padding: 0;

    font-weight: 500;
  }

  &__date {
    color: $color-grey;
  }

  &__text::before {
    content: "";
    position: absolute;
    bottom: -18px;
    left: 21px;

    width: 18px;
    height: 18px;

    background-image: url("../img/svg/triangle.svg");
    background-repeat: no-repeat;
  }
}

@include vp--1023 {
  .reviews {
    padding-bottom: 50px;

    &__list {
      flex-direction: column;
      align-items: center;
    }

    &__item {
      flex-direction: column;
      width: 320px;
    }

    &__item:nth-child(odd) {
      margin-right: 0;
    }

    &__picture {
      margin-bottom: 20px;
    }

    &__text {
      width: auto;
    }
  }
}

@include vp--767 {
  .reviews {
    padding-bottom: 35px;
  }
}

@media (max-width: 400px) {
  .reviews {
    &__heading {
      margin-bottom: 10px;
    }

    &__text {
      padding: 10px;
    }

    &__item {
      width: auto;
    }
  }
}
