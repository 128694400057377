.banner {
  position: relative;

  width: 248px;
  padding: 18px;
  padding-left: 15px;

  color: $color-default-white;

  background-color: $color-green;
  background-image: url("../img/content/banner.png");
  background-repeat: no-repeat;

  &__title {
    margin: 0;
    margin-bottom: 5px;

    font-family: $font-family--montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
  }

  &__percent {
    margin: 0;
    margin-bottom: 5px;

    font-weight: bold;
    font-size: 80px;
    line-height: 1.4;
    text-align: center;
    letter-spacing: -0.05em;
  }

  &__description {
    margin: 0;
    margin-top: -15px;
    margin-bottom: 20px;

    font-size: 18px;
    line-height: 1.4;
    text-align: center;
  }

  &__phone {
    margin: 0;

    font-weight: bold;
    font-size: 24px;
    line-height: 1.4;
    text-align: center;
  }
}
