.photo {
  display: flex;
  align-items: flex-start;
  margin-bottom: 78px;

  &__picture {
    width: 144px;
    height: 193px;
    margin-right: 16px;
    padding: 10px;
    padding-bottom: 13px;

    border: 1px solid $color-medium-grey;
    border-radius: 3px;
  }

  &__image {
    width: 124px;
    height: 170px;
    cursor: pointer;
  }
}
