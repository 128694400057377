.arrow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  background-color: $color-light-grey;
  transition: $trans--300;
  border-radius: 50%;
  border: none;
  outline: none;

  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;

    border-top: 3px solid $color-default-dark-grey;
    border-right: 3px solid $color-default-dark-grey;
    transform: rotate(-135deg);
  }

  &--right {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: $color-green;

    &::after {
      border-top-color: $color-default-white;
      border-right-color: $color-default-white;
    }
  }
}
