.promo {
  padding-top: 55px;
  padding-bottom: 48px;

  &__container {
    display: grid;

    grid-column-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  &__main {
    position: relative;

    height: 100%;
    padding-bottom: 20px;

    transition: $trans--300;

    grid-column-end: 3;
    grid-column-start: 1;

    &:hover {
      opacity: $opacity--main;
    }
  }

  &__link {
    text-decoration: none;
  }

  &__link:hover &__checkbox {
    opacity: $opacity--main;
  }

  &__image {
    width: 100%;
    height: 100%;

    object-fit: cover;
  }

  &__explanation {
    position: absolute;
    bottom: 34px;
    left: 39px;
  }

  &__item {
    min-height: 221px;
    margin-bottom: 20px;
    padding: 16px 20px;
  }

  &__img-wrap {
    height: 100%;

    background-color: $zorba;
    user-select: none;
  }

  &__calc {
    position: relative;

    margin-bottom: 19px;
    overflow: hidden;
    user-select: none;

    background-color: $color-floral-white;
    background-repeat: no-repeat;
  }

  &__calc::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: 17px;

    width: 189px;
    height: 186px;

    background-image: url("../img/style/second-promo-bg.svg");
    opacity: 0.2;
  }

  &__calc::after {
    content: "";
    position: absolute;
    right: -155px;
    bottom: 22px;

    width: 184px;
    height: 178px;

    background-color: $color-floral-white;
    border-radius: 100%;
  }

  &__banner {
    position: relative;

    min-height: 220px;
    padding-bottom: 12px;
    padding-left: 25px;

    background-color: $color-green;
    cursor: default;
  }

  &__banner::before {
    content: attr(data-percent) " ";
    position: absolute;
    right: 10px;
    bottom: 56px;

    width: 208px;
    height: 152px;

    font-weight: 700;
    font-size: 210px;
    line-height: 210px;
    color: $color-default-white;
    letter-spacing: -0.06em;

    opacity: 0.1;
  }

  &__title {
    margin: 0;

    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    font-family: $font-family--montserrat;
    color: $color-default-white;
  }

  &__title-banner {
    margin: 0;
  }

  &__checkbox {
    position: relative;
    z-index: 10;

    width: 109px;
    height: 58px;
    margin-left: 5px;
    padding-top: 4px;
    padding-left: 52px;

    background-image: url("../img/style/second-promo-check.svg");
    background-repeat: no-repeat;

    transition: $trans--300;
  }

  &__switcher {
    display: block;
    width: 50px;
    height: 50px;

    background-color: $color-default-white;
    border-radius: 100%;
  }

  &__description {
    display: block;

    line-height: 26px;
    color: $color-default-white;
  }

  &__percent {
    margin: 0;
    margin-bottom: 10px;
    margin-left: -9px;

    font-weight: 700;
    font-size: 80px;
    line-height: 112px;
    color: $color-default-white;
  }

  &__link:hover {
    opacity: $opacity--main;
  }

  &__description--main {
    margin-top: 4px;
  }

  &__title--banner {
    margin-bottom: 12px;
  }

  &__title--calc {
    width: 187px;
    margin-bottom: 65px;

    color: $color-default-dark-grey;
  }

  &__description--banner {
    margin: 0;
  }
}

@include vp--1023 {
  .promo {
    padding-top: 40px;
    padding-bottom: 44px;

    &__container {
      padding-top: 20px;
      padding-bottom: 0;

      grid-template-columns: 1fr;
    }

    &__main {
      grid-column-end: 1;
    }

    &__calc {
      padding-top: 10px;
      padding-left: 16px;
    }

    &__calc::before {
      right: -22px;
    }

    &__calc::after {
      content: "";
      position: absolute;
      right: -168px;
      bottom: 22px;

      width: 184px;
      height: 178px;
    }

    &__banner {
      flex-shrink: 1;
    }

    &__title--calc {
      margin-bottom: 75px;
    }

    &__title--banner {
      margin-bottom: 21px;
    }

    &__checkbox {
      margin-left: -8px;
    }

    &__explanation {
      bottom: 30px;
      left: 16px;
    }

    &__title {
      font-size: 20px;
      line-height: 28px;
    }

    &__description {
      font-size: 15px;
      line-height: 24px;
    }

    &__description--banner {
      font-size: 16px;
      line-height: 22px;
    }

    &__item {
      width: unset;
      margin-bottom: 20px;
    }

    &__percent {
      margin-left: -6px;
    }

    &__extra {
      display: grid;
      width: 100%;

      column-gap: 20px;

      grid-template-columns: 1fr 1fr;
    }

    &__link {
      flex-shrink: 1;
      width: 100%;
      margin-right: 20px;
    }
  }
}

@include vp--767 {
  .promo {
    padding-top: 1px;
    padding-bottom: 0;

    &__extra {
      display: grid;

      grid-template-columns: 1fr;
    }

    &__container {
      padding-top: 44px;
      padding-bottom: 47px;
    }

    &__item {
      margin-bottom: 15px;
    }

    &__link {
      width: 100%;
    }

    &__banner {
      width: 100%;
      padding-bottom: 10px;
      padding-left: 17px;
    }

    &__title--banner {
      margin-bottom: 19px;
    }

    &__img-wrap {
      height: unset;
    }

    &__main {
      padding-bottom: 0;
    }

    &__explanation {
      bottom: 36px;
    }
  }
}
