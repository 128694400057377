.about-contacts {
  width: 448px;
  padding: 46px 20px 54px 59px;

  border-right: 1px solid $color-medium-grey;

  &__heading {
    margin-bottom: 19px;
  }

  &__list {
    margin: 0;
    padding: 0;

    list-style: none;
  }

  &__item {
    margin-bottom: 26px;
  }

  &__item:last-child {
    margin-bottom: 0;
  }

  &__title {
    margin: 0;
    margin-bottom: 5px;
    padding: 0;

    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    font-family: $font-family--montserrat;
    letter-spacing: 0.02em;
  }

  &__value--address {
    display: block;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    font-family: $font-family--montserrat;
    color: $color-grey;
    letter-spacing: 0.02em;
  }
}

@include vp--1023 {
  .about-contacts {
    width: auto;

    border-right: none;
    border-bottom: 1px solid $color-medium-grey;

    &__list {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    &__item {
      width: 296px;
      margin-right: 40px;
    }
  }
}

@media (max-width: 400px) {
  .about-contacts {
    padding: 5%;

    &__item {
      margin-right: 0;
    }
  }
}
