.button {
  display: inline-block;
  padding: 14px;

  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: $color-default-white;

  border: 2px solid $color-green;
  background-color: $color-green;
  border-radius: 4px;
  transition: $trans--300;
  user-select: none;
  text-decoration: none;

  &:hover {
    color: $color-green;
    border: 2px solid $color-green;

    background-color: $color-default-white;
  }

  &--callback {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 9px;
    padding-top: 13px;
  }

  &--slider {
    display: inline-block;
    padding: 14px 16px 13px 16px;

    @include vp--1023 {
      width: 100%;
      font-size: 11px;
      line-height: 12px;
      padding: 12px 10px 12px 10px;
    }
  }
}
