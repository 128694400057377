.works {
  padding-top: 57px;
  padding-bottom: 100px;
}

.works__heading {
  display: block;
  margin-bottom: 21px;
  padding-right: 27px;
  text-align: center;
}

.works__description {
  margin-bottom: 39px;
}

@include vp--767 {
  .works {
    padding-top: 41px;
    padding-bottom: 64px;
  }

  .works__heading {
    display: block;
    margin-bottom: 23px;
    padding-right: 0;
  }

  .works__description {
    margin-bottom: 37px;
  }
}
