.features {
  padding-top: 62px;
  padding-bottom: 30px;

  &__heading {
    max-width: 562px;
    margin: 0 auto 33px auto;
    padding-right: 20px;

    text-align: center;
    font-family: $font-family--montserrat;
    font-weight: 500;
  }

  &__description {
    font-weight: 300;
  }

  &__description--main {
    margin-bottom: 61px;
  }

  &__item-description {
    margin-left: 5px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -75px 0 0;
    padding: 0 52px 0 40px;

    list-style: none;
  }

  &__item {
    max-width: 310px;
    margin-right: 75px;
    margin-bottom: 50px;
  }

  &__title {
    margin: 0;
    margin-bottom: 20px;

    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    font-family: $font-family--montserrat;
    text-align: center;
    letter-spacing: 0.02em;
  }

  &__img {
    position: relative;

    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  &__img--production {
    top: 8px;
  }

  &--catalog {
    margin: 0;
    padding: 0;

    background-color: $color-default-white;

    .container {
      padding: 0;
    }

    .features__heading {
      margin: 0;
      margin-bottom: 12px;

      font-size: 18px;
      line-height: 1.6;
      text-align: left;
      letter-spacing: 0.02em;
    }

    .features__list {
      justify-content: flex-start;
      margin: 0;
      padding: 0;
    }

    .features__item {
      position: relative;

      max-width: 35%;
      margin-right: 0;
    }

    .features__item:not(:last-child) {
      margin-right: 10%;
    }

    .features__img {
      position: absolute;

      display: block;
      margin: 0;
      margin-top: 5px;
    }

    .features__img--pencil-ruler-color {
      width: 45px;
      height: 60px;

      background-image: url("../img/content/pencil-ruler-color.png");
      background-repeat: no-repeat;
    }

    .features__img--warranty {
      width: 55px;
      height: 60px;
      margin-left: -9px;

      background-image: url("../img/content/warranty.png");
      background-repeat: no-repeat;
    }

    .features__title {
      margin-bottom: 7px;
      padding: 0;

      text-align: left;
    }

    .features__content {
      margin-left: 0;
      padding-left: 61px;

      text-align: left;
    }

    .features__description {
      width: auto;
      max-width: 850px;
      margin: 0;
      margin-bottom: 32px;

      text-align: left;
    }

    .features__item-description {
      margin: 0;

      font-weight: 300;
      font-size: 14px;
      line-height: 1.5;
    }
  }
}

@include vp--1023 {
  .features {
    padding-top: 60px;
    padding-bottom: 6px;

    &__heading {
      margin-right: auto;
      margin-bottom: 23px;
      margin-left: auto;
    }

    &__description {
      margin-right: auto;
      margin-bottom: 46px;
      margin-left: auto;
    }

    &__description--main {
      width: 340px;
      margin-bottom: 42px;
    }

    &__item-description {
      margin-left: 0;
    }

    &__item {
      margin-bottom: 22px;
    }

    &__list {
      padding: 0;
    }

    &__img {
      margin-bottom: 22px;
    }

    &--catalog {
      padding: 0;

      .features__heading {
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        font-size: 18px;
        line-height: 1.6;
        letter-spacing: 0.02em;
      }

      .features__description {
        width: 100%;
      }

      .features__list {
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .features__item:not(:last-child) {
        margin-right: 0;
      }

      .features__item {
        max-width: 310px;
        margin-bottom: 40px;
      }
    }
  }
}

@include vp--767 {
  .features {
    &__description {
      margin-bottom: 43px;

      &--main {
        width: 100%;
        max-width: 340px;
      }
    }

    &__item {
      margin-right: 0;
      margin-bottom: 28px;
    }

    &__list {
      margin-right: 0;
    }

    &__heading {
      padding-right: 0;
    }

    &--catalog {
      .features__item:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}
