.footer {
  min-height: 100px;

  .social__link:hover {
    background-color: $color-brown;
    opacity: 1;
  }

  &__top {
    padding-top: 41px;
    padding-bottom: 40px;

    color: $color-grey;

    background-color: $color-beige;
  }

  &__bottom {
    padding-top: 29px;
    padding-bottom: 42px;

    font-size: 14px;
    line-height: 20px;
    color: $color-default-white;

    background-color: $color-brown;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__container--bottom {
    max-width: 360px;
  }

  &__contacts {
    margin: 0;
    margin-bottom: 25px;
    padding: 0;

    list-style: none;
  }

  &__contacts-wrapper {
    margin: 0;
    margin-bottom: 4px;

    text-align: center;
  }

  &__contacts-wrapper:last-child {
    margin-bottom: 0;
  }

  &__contacts-link {
    display: block;
  }

  &__menu {
    margin-bottom: 14px;
  }

  &__social {
    margin-bottom: 27px;
  }

  &__social-title {
    display: none;
  }

  &__dev-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 300px;
    padding-left: 13px;

    text-align: center;
  }

  &__dev-info {
    margin: 0;
  }

  &__copyright {
    margin: 0;
    margin-bottom: 10px;

    text-align: center;
  }

  &__copyright-text {
    display: block;
  }
}

@media (min-width: $vp--1024) {
  .footer {
    &__top {
      padding-top: 43px;
      padding-bottom: 29px;
    }

    &__bottom {
      padding-top: 18px;
      padding-right: 58px;
      padding-bottom: 17px;
      padding-left: 60px;
    }

    &__container {
      display: flex;
      flex-direction: row;
    }

    &__container--top {
      align-items: flex-start;
    }

    &__container--bottom {
      align-items: center;
      max-width: 1260px;
    }

    &__contacts {
      margin-right: 11.5%;
      margin-bottom: 0;
    }

    &__contacts-wrapper {
      width: 174px;

      text-align: left;
    }

    &__menu {
      margin-right: 2.8%;
      margin-bottom: 0;
    }

    &__social {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 6.5%;
      margin-bottom: 0;
    }

    &__social-title {
      display: block;
      margin: 0;
      margin-bottom: 11px;

      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
    }

    &__dev-info-container {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 23%;

      text-align: left;
    }

    &__dev-info {
      margin-bottom: 5px;
      padding-left: 0;
    }

    &__copyright {
      margin-right: 185px;
      margin-bottom: 0;

      text-align: left;
    }
  }
}

@include vp--1023 {
  .footer {
    &__top {
      padding-bottom: 42px;
    }
  }
}
