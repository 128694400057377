.main-nav {
  position: relative;

  width: 100%;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 1px;
    margin-left: calc(50% - 50vw);

    background-color: $color-light-grey;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;

    width: 100vw;
    height: 1px;
    margin-left: calc(50% - 50vw);

    background-color: $color-light-grey;
  }

  @include vp--1023 {
    border: none;

    @include vp--1023 {
      margin-bottom: 5px;
    }
  }

  &__inner {
    @include vp--1023 {
      padding: 0;
    }
  }

  &__list {
    display: flex;
    margin: 0 -19px 0  -19px;
    padding: 0;

    list-style: none;

    @include vp--1023 {
      flex-direction: column;
      margin: 0;
    }
  }

  &__item {
    position: relative;

    align-items: center;
    flex-shrink: 1;
    flex-grow: 1;
    //white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 1250px) {
      margin-right: auto;
    }

    @include vp--1023 {
      width: 100%;
      margin-right: 0;

      border-bottom: 1px solid $color-light-grey;
    }
  }

  &__item .main-nav__subnav {
    @include desktop-width-only {
      // display: block;
      display: none;
      // width: max-content;
    }
  }

  &__item:hover .main-nav__subnav {
    @include desktop-width-only {
      opacity: 1;
      display: inline-block;
      top: unset;
    }
  }

  &__item--dropdown {
    &:hover::after {
      border-top: 2px solid $color-green;
      border-right: 2px solid $color-green;
    }
  }

  &__item--dropdown .main-nav__link {
    @include desktop-width-only {
      padding-right: 15px;
      transition: $trans--300;
    }
  }

  &__item--dropdown::after {
    content: "";

    position: absolute;
    top: 21px;
    right: 14%;

    width: 6px;
    height: 6px;

    border-top: 2px solid $color-eclipse;
    border-right: 2px solid $color-eclipse;
    transform: rotate(135deg);

    @include vp--1023 {
      top: 20px;
      right: 20px;

      transform: rotate(45deg);
    }
  }

  &__link {
    display: block;
    width: 100%;
    padding-top: 18px;
    padding-bottom: 18px;
    outline: none;
    transition: $trans--300;
    text-align: center;

    @media (max-width: 1250px) {
      margin-right: auto;
    }

    @include vp--1023 {
      width: 100%;
      padding-left: 20px;
      padding-right: 50px;
      padding-top: 16px;
      padding-bottom: 17px;
      text-align: left;
    }
  }

  &__subnav {
    position: absolute;
    // left: 16px;
    // top: 51px;
    z-index: 1;

    padding: 27px;

    list-style: none;
    background-color: $color-default-white;
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.06), 0 2px 6px rgba(0, 0, 0, 0.04), 0 0 1px rgba(0, 0, 0, 0.04);

    @include vp--1023 {
      position: static;

      padding: 0;
      padding-top: 13px;
      padding-left: 4px;
      padding-bottom: 20px;

      box-shadow: none;
      border-top: 1px solid $color-light-grey;
    }
  }

  &__subnav-item {
    max-width: 215px;
    width: 100%;

    @include vp--1023 {
      width: 100%;
      margin-bottom: 5px;
      padding-left: 20px;
      padding-right: 40px;
    }
  }

  &__subnav-item:last-child {
    @include vp--1023 {
      margin-bottom: 0;
    }
  }
}
