.modal-callback {
  &__title {
    margin: 0;
    margin-bottom: 17px;

    font-weight: 500;
    font-size: 21px;
    line-height: 1.4;
    text-align: center;
  }

  &__field {
    &:not(:last-of-type) {
      margin-bottom: 11px;
    }
  }

  &__field label {
    display: block;
    margin-bottom: 7px;

    font-weight: 500;
    font-size: 14px;
    line-height: 1.43;

    sup {
      top: -3px;
      font-family: $font-family--sourcesanspro;
      font-weight: 600;
      font-size: 13px;
      line-height: 1.54;
    }
  }

  &__field input {
    display: block;
    width: 100%;
    padding: 12px 15px;

    border: 1px solid $color-medium-grey;
    box-sizing: border-box;
    border-radius: 4px;
    outline: none;
  }

  &__field input::-webkit-input-placeholder {
    color: rgba($color-default-dark-grey, 0.4);
    font-weight: 500;
  }

  &__field input::-moz-placeholder {
    color: rgba($color-default-dark-grey, 0.4);
    font-weight: 500;
  }

  &__field input::-ms-input-placeholder {
    color: rgba($color-default-dark-grey, 0.4);
    font-weight: 500;
  }

  &__field input:hover {
    opacity: $opacity--main;
  }

  &__field input:active,
  &__field input:focus {
    border-color: $color-grey;
    opacity: 1;
  }


  &__field select {
    display: block;
    width: 100%;
    padding: 12px 15px;

    color: rgba($color-default-dark-grey, 0.4);
    font-weight: 500;
    border: 1px solid $color-medium-grey;
    box-sizing: border-box;
    border-radius: 4px;
    appearance: none;
    outline: none;
    cursor: pointer;

    &:hover {
      opacity: $opacity--main;
    }

    &:active,
    &:focus {
      border-color: $color-grey;
      opacity: 1;
    }
  }

  &__form p {
    margin: 0;
    max-width: 300px;

    font-weight: 300;
    font-size: 12px;
    line-height: 1.5;
  }

  &__form a {
    color: $color-brown;
    text-decoration: underline;

    &:hover {
      opacity: $opacity--main;
    }
  }

  &__button {
    margin-bottom: 20px;
    margin-top: 20px;
    cursor: pointer;
  }

  &__error {
    display: none;
  }
}

// Стили ошибки заполнения полей
.modal-callback__field--error {
  .modal-callback__input {
    border-color: $color-red;
    margin-bottom: 8px;
  }

  .modal-callback__error {
    position: relative;
    display: block;
    margin-top: 10px;
    padding: 5px 15px 5px 40px;
    color: $color-red;
    background-color: rgba($color-red, 0.2);
    border-radius: 5px;

    &::before {
      position: absolute;
      content: "";
      width: 16px;
      height: 16px;
      top: 50%;
      left: 17px;
      transform: translateY(-58%);
      background-image: url("../img/style/warning.png");
    }
  }
}
