.about-us {
  display: flex;
  justify-content: space-between;
  padding-top: 34px;
  padding-bottom: 32px;

  &__explanation {
    width: 46%;
    margin-right: 51px;
    margin-bottom: 30px;

    font-weight: 300;
    line-height: 26px;
  }

  &__first {
    margin-bottom: 25px;
  }

  &__features {
    width: 49%;
  }

  &__list {
    margin: 0;
    padding: 0;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 17px;
    row-gap: 61px;
    list-style: none;
  }

  &__item {
    position: relative;

    padding-left: 60px;
  }

  &__item::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 0;

    width: 40px;
    height: 40px;

    background-repeat: no-repeat;
  }

  .about-us__item--consult::before {
    background-image: url("../img/style/about-feature-first.png");
  }

  .about-us__item--design::before {
    background-image: url("../img/style/about-feature-third.png");
  }

  .about-us__item--limit::before {
    background-image: url("../img/style/about-feature-second.png");
  }

  .about-us__item--guarantee::before {
    background-image: url("../img/style/about-feature-fourth.png");
  }

  &__title {
    margin: 0;
    margin-bottom: 7px;

    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    font-family: $font-family--montserrat;
    letter-spacing: 0.02em;
  }

  &__description {
    margin: 0;

    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
  }
}

@include vp--1023 {
  .about-us {
    padding-top: 22px;
    padding-bottom: 80px;
    flex-direction: column;

    &__explanation {
      width: 100%;
      margin-right: 0;
      margin-bottom: 30px;
    }

    &__features {
      width: 100%;
    }

    &__list {
      row-gap: 40px;
    }
  }
}

@include vp--767 {
  .about-us {
    margin-left: 5%;
    margin-right: 5%;
    padding-bottom: 75px;

    &__explanation {
      width: auto;
      margin-bottom: 30px;
    }

    &__features {
      width: auto;
    }

    &__item {
      padding-left: 55px;
    }

    &__list {
      grid-template-columns: 1fr;
      column-gap: 17px;
      row-gap: 61px;
    }
  }
}
